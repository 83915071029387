.bottomPanel {
  position: fixed;
  bottom: 0;
  height: 100%;
  max-height: 13vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001;
}
