.background {
  top: 0;
  bottom: 0;
  max-width: 100%;
  min-width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
  &.noscroll {
    .background-img {
      height: 100%;
    }
  }
  .background-img {
    position: absolute;
    object-fit: cover;
    width: 100%;

    z-index: -1;
  }
}

.content {
  position: absolute;
  bottom: 250px;
  // top: 22%;

  top: 225px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.wheel-content {
  display: flex;
  height: 100vh;
  z-index: 1;
  width: -webkit-fill-available;
  width: -moz-available;
}

@media (max-width: 420px) {
  .content {
    top: 110px;
  }
}

@media (max-height: 720px) {
  .noscroll {
    .content {
      top: 110px;
    }
  }
}

.go-to-planets-button {
  background: url("../../assets/modals/Back.png") no-repeat center;

  background-size: contain;
  width: 125px;
  height: 50px;
  color: #613405;
  text-transform: uppercase;
  position: fixed;
  bottom: 14%;
  left: 5%;
}

/* Контейнер для кнопок */
.action-buttons {
  display: flex;
  flex-direction: column; /* Размещаем кнопки вертикально */
  position: fixed;
  bottom: 10%;
  right: 5%;
}

/* Общие стили для кнопок */
.action-buttons button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff396;
  background: linear-gradient(to right, #efc437, #f59809);
  box-shadow: inset 0 -3px 0 #613405;
  cursor: pointer;

  display: flex; /* Центрируем содержимое кнопки */
  align-items: center;
  justify-content: center;

  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.action-buttons button:hover {
  transform: translateY(-3px);
}

.action-buttons button:active {
  transform: translateY(3px);
  box-shadow: inset 0 2px 0 #8a4b00;
}

.action-buttons button img {
  width: 20px;
  height: 20px;
}
